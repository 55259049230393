import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DocumentData } from "firebase/firestore";
import { PieChartsSection } from "./PieChartsSection";
import SummarySection from "./SummarySection";
import ExchangeTable from "./ExchangeTable";
import ExchangesBalancesSection from "./ExchangesBalancesSection";
import { getMetricsInfo, getOrgInfo } from "../../modules/utils";
import styles from "./kpi-dashboard.module.scss";

export const KpiDashboard = () => {
  const { orgId } = useParams();
  const [internalOrgName, setInternalOrgName] = useState<string>();
  const [metric, setMetric] = useState<DocumentData | undefined>();
  const [filterByMM, setFilterByMM] = useState<boolean>(true);
  const [filterByVolume, setFilterByVolume] = useState<boolean>(false);
  const [filterByAnomaly, setFilterByAnomaly] = useState<boolean>(true);
  const [filterByCEX, setFilterByCEX] = useState<boolean>(false);
  const [filterByExchanges, setFilterByExchanges] = useState<boolean>(false);

  const getOrganizationInfo = async (orgId: string) => {
    const organization = await getOrgInfo(orgId);
    if (organization) {
      setInternalOrgName(organization.internalOrganizationName);
    }
  };

  const filters = {
    filterByMM,
    filterByVolume,
    filterByAnomaly,
    filterByCEX,
    filterByExchanges
  };

  const setFilters = {
    setFilterByMM,
    setFilterByVolume,
    setFilterByAnomaly,
    setFilterByCEX,
    setFilterByExchanges
  };

  useEffect(() => {
    if (orgId) {
      getOrganizationInfo(orgId);
    }
  }, [orgId]);

  const getExchangeInfo = async (token: string) => {
    const res = await getMetricsInfo(token);
    if (res) {
      setMetric(res);
    }
  };

  useEffect(() => {
    if (internalOrgName) {
      getExchangeInfo(internalOrgName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalOrgName]);

  return (
    <div className={styles.wrapper}>
      <SummarySection metric={metric} filters={filters}/>
      <PieChartsSection metric={metric} filters={filters} />
      <ExchangeTable
        metric={metric}
        filters={filters}
        setFilters={setFilters}
      />
      <ExchangesBalancesSection metric={metric} />
    </div>
  );
};
